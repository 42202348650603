import React from 'react';

import TruthTableBuilder from './containers/TruthTableBuilder';


function App() {
  return (
    <div>
      <TruthTableBuilder />
    </div>  
  );
}

export default App;
